<template>
  <div  class="corneaMap" id="corneaMap">
    <h3><PopularScience :text="'角膜地形图'" :gjz="'jiaomodixingtu'"></PopularScience></h3>
    <ul class="xxxx">
      <li></li>
      <li style="width: 18vw;">角膜最平坦K值(K1)</li>
      <li style="width: 0"></li>
      <li style="width: 18vw;">角膜最陡峭K值(K2)</li>
      <li style="width: 0"></li>
      <li>散光</li>
      <li>Kappa角</li>
      <li>暗瞳</li>
    </ul>
    <ul class="xxxx">
      <li>OD</li>
      <li>
<!--        <el-input class="pj w15"  @change="zh('CL_OP_CTM_OD_FlatK1')" :class="postData.CL_OP_CTM_OD_FlatK1&&(postData.CL_OP_CTM_OD_FlatK1 < 0 || postData.CL_OP_CTM_OD_FlatK1 > 60)?'cw':''" v-model="postData.CL_OP_CTM_OD_FlatK1" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OD_FlatK1, 0, 60)?'cw':'', srval==='CL_OP_CTM_OD_FlatK1'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OD_FlatK1, 'CL_OP_CTM_OD_FlatK1')" v-model="postData.CL_OP_CTM_OD_FlatK1" placeholder="请输入内容"></el-input>
        <span>@</span>
      </li>
      <li>
<!--        <el-input class="pj w15" @change="zh('CL_OP_CTM_OD_FlatK2')" :class="postData.CL_OP_CTM_OD_FlatK2&&(postData.CL_OP_CTM_OD_FlatK2 < 0 || postData.CL_OP_CTM_OD_FlatK2 > 180)?'cw':''" v-model="postData.CL_OP_CTM_OD_FlatK2" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OD_FlatK2, 0, 180)?'cw':'', srval==='CL_OP_CTM_OD_FlatK2'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OD_FlatK2, 'CL_OP_CTM_OD_FlatK2')" v-model="postData.CL_OP_CTM_OD_FlatK2" placeholder="请输入内容"></el-input>
      </li>
      <li>
<!--        <el-input class="pj w15" @change="zh('CL_OP_CTM_OD_SteepK1')" :class="postData.CL_OP_CTM_OD_SteepK1&&(postData.CL_OP_CTM_OD_SteepK1 < 0 || postData.CL_OP_CTM_OD_SteepK1 > 60)?'cw':''" v-model="postData.CL_OP_CTM_OD_SteepK1" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OD_SteepK1, 0, 60)?'cw':'', srval==='CL_OP_CTM_OD_SteepK1'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OD_SteepK1, 'CL_OP_CTM_OD_SteepK1')" v-model="postData.CL_OP_CTM_OD_SteepK1" placeholder="请输入内容"></el-input>
        <span>@</span>
      </li>
      <li>
<!--        <el-input class="pj w15" @change="zh('CL_OP_CTM_OD_SteepK2')" :class="postData.CL_OP_CTM_OD_SteepK2&&(postData.CL_OP_CTM_OD_SteepK2 < 0 || postData.CL_OP_CTM_OD_SteepK2 > 180)?'cw':''" v-model="postData.CL_OP_CTM_OD_SteepK2" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OD_SteepK2, 0, 180)?'cw':'', srval==='CL_OP_CTM_OD_SteepK2'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OD_SteepK2, 'CL_OP_CTM_OD_SteepK2')" v-model="postData.CL_OP_CTM_OD_SteepK2" placeholder="请输入内容"></el-input>
      </li>
      <li>
<!--        <el-input class="pj" @change="zh('CL_OP_CTM_OD_Cylinder')" :class="postData.CL_OP_CTM_OD_Cylinder&&(postData.CL_OP_CTM_OD_Cylinder < 0 || postData.CL_OP_CTM_OD_Cylinder > 9)?'cw':''" v-model="postData.CL_OP_CTM_OD_Cylinder" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OD_Cylinder, 0, 9)?'cw':'', srval==='CL_OP_CTM_OD_Cylinder'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OD_Cylinder, 'CL_OP_CTM_OD_Cylinder')" v-model="postData.CL_OP_CTM_OD_Cylinder" placeholder="请输入内容"></el-input>
      </li>
      <li>
        <!--        <el-input class="pj" @change="zh('CL_OP_CTM_OD_Cylinder')" :class="postData.CL_OP_CTM_OD_Cylinder&&(postData.CL_OP_CTM_OD_Cylinder < 0 || postData.CL_OP_CTM_OD_Cylinder > 9)?'cw':''" v-model="postData.CL_OP_CTM_OD_Cylinder" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OD_Kappa, 0, 9)?'cw':'', srval==='CL_OP_CTM_OD_Kappa'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OD_Kappa, 'CL_OP_CTM_OD_Kappa')" v-model="postData.CL_OP_CTM_OD_Kappa" placeholder="请输入内容"></el-input>
      </li>
      <li>
        <!--        <el-input class="pj" @change="zh('CL_OP_CTM_OD_Cylinder')" :class="postData.CL_OP_CTM_OD_Cylinder&&(postData.CL_OP_CTM_OD_Cylinder < 0 || postData.CL_OP_CTM_OD_Cylinder > 9)?'cw':''" v-model="postData.CL_OP_CTM_OD_Cylinder" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OD_AnTong, 0, 9)?'cw':'', srval==='CL_OP_CTM_OD_AnTong'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OD_AnTong, 'CL_OP_CTM_OD_AnTong')" v-model="postData.CL_OP_CTM_OD_AnTong" placeholder="请输入内容"></el-input>
      </li>
    </ul>
    <ul class="xxxx">
      <li>OS</li>
      <li>
<!--        <el-input class="pj" @change="zh('CL_OP_CTM_OS_FlatK1')" :class="postData.CL_OP_CTM_OS_FlatK1&&(postData.CL_OP_CTM_OS_FlatK1 < 0 || postData.CL_OP_CTM_OS_FlatK1 > 60)?'cw':''" v-model="postData.CL_OP_CTM_OS_FlatK1" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OS_FlatK1, 0, 60)?'cw':'', srval==='CL_OP_CTM_OS_FlatK1'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OS_FlatK1, 'CL_OP_CTM_OS_FlatK1')" v-model="postData.CL_OP_CTM_OS_FlatK1" placeholder="请输入内容"></el-input>
        <span>@</span>
      </li>
      <li>
<!--        <el-input class="pj" @change="zh('CL_OP_CTM_OS_FlatK2')" :class="postData.CL_OP_CTM_OS_FlatK2&&(postData.CL_OP_CTM_OS_FlatK2 < 0 || postData.CL_OP_CTM_OS_FlatK2 > 180)?'cw':''" v-model="postData.CL_OP_CTM_OS_FlatK2" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OS_FlatK2, 0, 180)?'cw':'', srval==='CL_OP_CTM_OS_FlatK2'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OS_FlatK2, 'CL_OP_CTM_OS_FlatK2')" v-model="postData.CL_OP_CTM_OS_FlatK2" placeholder="请输入内容"></el-input>
      </li>
      <li>
<!--        <el-input class="pj" @change="zh('CL_OP_CTM_OS_SteepK1')" :class="postData.CL_OP_CTM_OS_SteepK1&&(postData.CL_OP_CTM_OS_SteepK1 < 0 || postData.CL_OP_CTM_OS_SteepK1 > 60)?'cw':''" v-model="postData.CL_OP_CTM_OS_SteepK1" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OS_SteepK1, 0, 60)?'cw':'', srval==='CL_OP_CTM_OS_SteepK1'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OS_SteepK1, 'CL_OP_CTM_OS_SteepK1')" v-model="postData.CL_OP_CTM_OS_SteepK1" placeholder="请输入内容"></el-input>
        <span>@</span>
      </li>
      <li>
<!--        <el-input class="pj" @change="zh('CL_OP_CTM_OS_SteepK2')" :class="postData.CL_OP_CTM_OS_SteepK2&&(postData.CL_OP_CTM_OS_SteepK2 < 0 || postData.CL_OP_CTM_OS_SteepK2 > 180)?'cw':''" v-model="postData.CL_OP_CTM_OS_SteepK2" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OS_SteepK2, 0, 180)?'cw':'', srval==='CL_OP_CTM_OS_SteepK2'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OS_SteepK2, 'CL_OP_CTM_OS_SteepK2')" v-model="postData.CL_OP_CTM_OS_SteepK2" placeholder="请输入内容"></el-input>
      </li>
      <li>
<!--        <el-input class="pj" @change="zh('CL_OP_CTM_OS_Cylinder')" :class="postData.CL_OP_CTM_OS_Cylinder&&(postData.CL_OP_CTM_OS_Cylinder < 0 || postData.CL_OP_CTM_OS_Cylinder > 9)?'cw':''" v-model="postData.CL_OP_CTM_OS_Cylinder" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OS_Cylinder, 0, 9)?'cw':'', srval==='CL_OP_CTM_OS_Cylinder'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OS_Cylinder, 'CL_OP_CTM_OS_Cylinder')" v-model="postData.CL_OP_CTM_OS_Cylinder" placeholder="请输入内容"></el-input>
      </li>
      <li>
        <!--        <el-input class="pj" @change="zh('CL_OP_CTM_OD_Cylinder')" :class="postData.CL_OP_CTM_OD_Cylinder&&(postData.CL_OP_CTM_OD_Cylinder < 0 || postData.CL_OP_CTM_OD_Cylinder > 9)?'cw':''" v-model="postData.CL_OP_CTM_OD_Cylinder" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OS_Kappa, 0, 9)?'cw':'', srval==='CL_OP_CTM_OS_Kappa'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OS_Kappa, 'CL_OP_CTM_OS_Kappa')" v-model="postData.CL_OP_CTM_OS_Kappa" placeholder="请输入内容"></el-input>
      </li>
      <li>
        <!--        <el-input class="pj" @change="zh('CL_OP_CTM_OD_Cylinder')" :class="postData.CL_OP_CTM_OD_Cylinder&&(postData.CL_OP_CTM_OD_Cylinder < 0 || postData.CL_OP_CTM_OD_Cylinder > 9)?'cw':''" v-model="postData.CL_OP_CTM_OD_Cylinder" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OS_AnTong, 0, 9)?'cw':'', srval==='CL_OP_CTM_OS_AnTong'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OS_AnTong, 'CL_OP_CTM_OS_AnTong')" v-model="postData.CL_OP_CTM_OS_AnTong" placeholder="请输入内容"></el-input>
      </li>
    </ul>


    <ul class="xxxx">
      <li></li>
      <li style="width: 18vw;">平面E值</li>
      <li style="width: 18vw;">斜面E值</li>
      <li style="width: 10.5vw;">瞳孔直径</li>
      <li style="width: 10.5vw;">角膜直径</li>
      <li>平均E</li>
    </ul>

    <ul class="xxxx">
      <li>OD</li>
      <li style="width: 18vw;">
<!--        <el-input class="pj" @change="zh('CL_OP_CTM_OD_Evalue')" :class="postData.CL_OP_CTM_OD_Evalue&&(postData.CL_OP_CTM_OD_Evalue < 0 || postData.CL_OP_CTM_OD_Evalue > 1.5)?'cw':''" v-model="postData.CL_OP_CTM_OD_Evalue" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OD_Evalue, 0, 1.5)?'cw':'', srval==='CL_OP_CTM_OD_Evalue'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OD_Evalue, 'CL_OP_CTM_OD_Evalue')" v-model="postData.CL_OP_CTM_OD_Evalue" placeholder="请输入内容"></el-input>
        <span>/</span>
<!--        <el-input class="pj" @change="zh('CL_OP_CTM_OD_Extra_Evalue')" :class="postData.CL_OP_CTM_OD_Extra_Evalue&&(postData.CL_OP_CTM_OD_Extra_Evalue < 0 || postData.CL_OP_CTM_OD_Extra_Evalue > 180)?'cw':''" v-model="postData.CL_OP_CTM_OD_Extra_Evalue" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OD_Extra_Evalue, 0, 180)?'cw':'', srval==='CL_OP_CTM_OD_Extra_Evalue'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OD_Extra_Evalue, 'CL_OP_CTM_OD_Extra_Evalue')" v-model="postData.CL_OP_CTM_OD_Extra_Evalue" placeholder="请输入内容"></el-input>
      </li>

      <li style="width: 18vw;">
<!--        <el-input class="pj" @change="zh('CL_OP_CTM_OD_S_Evalue')" :class="postData.CL_OP_CTM_OD_S_Evalue&&(postData.CL_OP_CTM_OD_S_Evalue < 0 || postData.CL_OP_CTM_OD_S_Evalue > 1.5)?'cw':''" v-model="postData.CL_OP_CTM_OD_S_Evalue" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OD_S_Evalue, 0, 1.5)?'cw':'', srval==='CL_OP_CTM_OD_S_Evalue'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OD_S_Evalue, 'CL_OP_CTM_OD_S_Evalue')" v-model="postData.CL_OP_CTM_OD_S_Evalue" placeholder="请输入内容"></el-input>
        <span>/</span>
<!--        <el-input class="pj" @change="zh('CL_OP_CTM_OD_S_Extra_Evalue')" :class="postData.CL_OP_CTM_OD_S_Extra_Evalue&&(postData.CL_OP_CTM_OD_S_Extra_Evalue < 0 || postData.CL_OP_CTM_OD_S_Extra_Evalue > 180)?'cw':''" v-model="postData.CL_OP_CTM_OD_S_Extra_Evalue" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OD_S_Extra_Evalue, 0, 180)?'cw':'', srval==='CL_OP_CTM_OD_S_Extra_Evalue'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OD_S_Extra_Evalue, 'CL_OP_CTM_OD_S_Extra_Evalue')" v-model="postData.CL_OP_CTM_OD_S_Extra_Evalue" placeholder="请输入内容"></el-input>
      </li>

      <li style="width: 10.5vw;">
<!--        <el-input class="pj" @change="zh('CL_OP_CTM_OD_PupilD')" :class="postData.CL_OP_CTM_OD_PupilD&&(postData.CL_OP_CTM_OD_PupilD < 9 || postData.CL_OP_CTM_OD_PupilD > 14)?'cw':''" v-model="postData.CL_OP_CTM_OD_PupilD" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OD_PupilD, 0, 10)?'cw':'', srval==='CL_OP_CTM_OD_PupilD'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OD_PupilD, 'CL_OP_CTM_OD_PupilD')" v-model="postData.CL_OP_CTM_OD_PupilD" placeholder="请输入内容"></el-input>
        <span>mm</span>
      </li>
      <li style="width: 10.5vw;">
<!--        <el-input class="pj" @change="zh('CL_OP_CTM_OD_Diameter')" :class="postData.CL_OP_CTM_OD_Diameter&&(postData.CL_OP_CTM_OD_Diameter < 1 || postData.CL_OP_CTM_OD_Diameter > 8)?'cw':''" v-model="postData.CL_OP_CTM_OD_Diameter" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OD_Diameter, 9, 14)?'cw':'', srval==='CL_OP_CTM_OD_Diameter'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OD_Diameter, 'CL_OP_CTM_OD_Diameter')" v-model="postData.CL_OP_CTM_OD_Diameter" placeholder="请输入内容"></el-input>
        <span>mm</span>
      </li>
      <li>
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[ srval==='CL_OP_CTM_OD_AvgE'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OD_AvgE, 'CL_OP_CTM_OD_AvgE')" v-model="postData.CL_OP_CTM_OD_AvgE" placeholder="请输入内容"></el-input>
      </li>
    </ul>
    <ul class="xxxx">
      <li>OS</li>
      <li style="width: 18vw;">
<!--        <el-input class="pj" @change="zh('CL_OP_CTM_OS_Evalue')" :class="postData.CL_OP_CTM_OS_Evalue&&(postData.CL_OP_CTM_OS_Evalue < 0 || postData.CL_OP_CTM_OS_Evalue > 1.5)?'cw':''"  v-model="postData.CL_OP_CTM_OS_Evalue" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OS_Evalue, 0, 1.5)?'cw':'', srval==='CL_OP_CTM_OS_Evalue'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OS_Evalue, 'CL_OP_CTM_OS_Evalue')" v-model="postData.CL_OP_CTM_OS_Evalue" placeholder="请输入内容"></el-input>
        <span>/</span>
<!--        <el-input class="pj" @change="zh('CL_OP_CTM_OS_Extra_Evalue')" :class="postData.CL_OP_CTM_OS_Extra_Evalue&&(postData.CL_OP_CTM_OS_Extra_Evalue < 0 || postData.CL_OP_CTM_OS_Extra_Evalue > 180)?'cw':''" v-model="postData.CL_OP_CTM_OS_Extra_Evalue" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OS_Extra_Evalue, 0, 180)?'cw':'', srval==='CL_OP_CTM_OS_Extra_Evalue'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OS_Extra_Evalue, 'CL_OP_CTM_OS_Extra_Evalue')" v-model="postData.CL_OP_CTM_OS_Extra_Evalue" placeholder="请输入内容"></el-input>
      </li>

      <li style="width: 18vw;">
<!--        <el-input class="pj" @change="zh('CL_OP_CTM_OS_S_Evalue')" :class="postData.CL_OP_CTM_OS_S_Evalue&&(postData.CL_OP_CTM_OS_S_Evalue < 0 || postData.CL_OP_CTM_OS_S_Evalue > 1.5)?'cw':''" v-model="postData.CL_OP_CTM_OS_S_Evalue" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OS_S_Evalue, 0, 1.5)?'cw':'', srval==='CL_OP_CTM_OS_S_Evalue'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OS_S_Evalue, 'CL_OP_CTM_OS_S_Evalue')" v-model="postData.CL_OP_CTM_OS_S_Evalue" placeholder="请输入内容"></el-input>
        <span>/</span>
<!--        <el-input class="pj" @change="zh('CL_OP_CTM_OS_S_Extra_Evalue')" :class="postData.CL_OP_CTM_OS_S_Extra_Evalue&&(postData.CL_OP_CTM_OS_S_Extra_Evalue < 0 || postData.CL_OP_CTM_OS_S_Extra_Evalue > 180)?'cw':''" v-model="postData.CL_OP_CTM_OS_S_Extra_Evalue" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OS_S_Extra_Evalue, 0, 180)?'cw':'', srval==='CL_OP_CTM_OS_S_Extra_Evalue'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OS_S_Extra_Evalue, 'CL_OP_CTM_OS_S_Extra_Evalue')" v-model="postData.CL_OP_CTM_OS_S_Extra_Evalue" placeholder="请输入内容"></el-input>
      </li>

      <li style="width: 10.5vw;">
<!--        <el-input class="pj" @change="zh('CL_OP_CTM_OS_PupilD')" :class="postData.CL_OP_CTM_OS_PupilD&&(postData.CL_OP_CTM_OS_PupilD < 9 || postData.CL_OP_CTM_OS_PupilD > 14)?'cw':''" v-model="postData.CL_OP_CTM_OS_PupilD" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OS_PupilD, 0, 10)?'cw':'', srval==='CL_OP_CTM_OS_PupilD'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OS_PupilD, 'CL_OP_CTM_OS_PupilD')" v-model="postData.CL_OP_CTM_OS_PupilD" placeholder="请输入内容"></el-input>
        <span>mm</span>
      </li>
      <li style="width: 10.5vw;">
<!--        <el-input class="pj" @change="zh('CL_OP_CTM_OS_Diameter')" :class="postData.CL_OP_CTM_OS_Diameter&&(postData.CL_OP_CTM_OS_Diameter < 1 || postData.CL_OP_CTM_OS_Diameter > 8)?'cw':''" v-model="postData.CL_OP_CTM_OS_Diameter" placeholder="请输入内容"></el-input>-->
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[srjc(postData.CL_OP_CTM_OS_Diameter, 9, 14)?'cw':'', srval==='CL_OP_CTM_OS_Diameter'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OS_Diameter, 'CL_OP_CTM_OS_Diameter')" v-model="postData.CL_OP_CTM_OS_Diameter" placeholder="请输入内容"></el-input>
        <span>mm</span>
      </li>
      <li>
        <el-input class="pj w15"  @keydown.native="keyd($event)" :class="[ srval==='CL_OP_CTM_OS_AvgE'?'xz':'']" @focus="getgb(postData.CL_OP_CTM_OS_AvgE, 'CL_OP_CTM_OS_AvgE')" v-model="postData.CL_OP_CTM_OS_AvgE" placeholder="请输入内容"></el-input>
      </li>
    </ul>

    <div class="tp">
      <UPFILE :imgtype="'CTM'" :wjtype="'角膜地形图'" :datas.sync="contentData"></UPFILE>
<!--      <UPfile :type="'CTM'"></UPfile>-->
<!--      <el-upload-->
<!--          action="#"-->
<!--          list-type="picture-card"-->
<!--          accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG"-->
<!--          :file-list="fileLists"-->
<!--          ref="uptq"-->
<!--          :on-change="sc"-->
<!--          :auto-upload="false">-->
<!--&lt;!&ndash;        <i slot="default" class="el-icon-plus"></i>&ndash;&gt;-->
<!--        <div>上传图片</div>-->
<!--        <div slot="file" slot-scope="{file}">-->
<!--          <img-->
<!--              class="el-upload-list__item-thumbnail"-->
<!--              :src="file.url" alt=""-->
<!--          >-->
<!--          <span class="el-upload-list__item-actions">-->
<!--        <span-->
<!--            class="el-upload-list__item-preview"-->
<!--            @click="handlePictureCardPreview(file)"-->
<!--        >-->
<!--          <i class="el-icon-zoom-in"></i>-->
<!--        </span>-->
<!--&lt;!&ndash;        <span&ndash;&gt;-->
<!--&lt;!&ndash;            v-if="!disabled"&ndash;&gt;-->
<!--&lt;!&ndash;            class="el-upload-list__item-delete"&ndash;&gt;-->
<!--&lt;!&ndash;            @click="handleDownload(file)"&ndash;&gt;-->
<!--&lt;!&ndash;        >&ndash;&gt;-->
<!--&lt;!&ndash;          <i class="el-icon-download"></i>&ndash;&gt;-->
<!--&lt;!&ndash;        </span>&ndash;&gt;-->
<!--        <span-->
<!--            v-if="!disabled"-->
<!--            class="el-upload-list__item-delete"-->
<!--            @click="handleRemove(file)"-->
<!--        >-->
<!--          <i class="el-icon-delete"></i>-->
<!--        </span>-->
<!--      </span>-->
<!--        </div>-->
<!--      </el-upload>-->
<!--      <el-dialog :visible.sync="dialogVisible">-->
<!--        <img width="100%" :src="dialogImageUrl" alt="">-->
<!--      </el-dialog>-->
    </div>

<!--    <div style="text-align: left;padding-top: 20px;">-->
<!--      <el-select class="xzlex"  v-model="valTypeidx" placeholder="请选择类型">-->
<!--        <el-option-->
<!--            v-for="item in options"-->
<!--            :key="item.value"-->
<!--            :label="item.label"-->
<!--            :value="item.value">-->
<!--        </el-option>-->
<!--      </el-select>-->
<!--      <el-button @click="dqwj" type="info">读取文件</el-button>-->
<!--      <el-button @click="dqsbsj" type="info">获取设备数据</el-button>-->
<!--    </div>-->

    <div class="bz">
      <p>备注：</p>
      <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
          placeholder="请输入内容"
          v-model="postData.CL_OP_CTM_Remarks">
      </el-input>
    </div>

    <el-dialog
        title="设备数据"
        :visible.sync="showsbsj"
        width="400px"
        >
      <div class="sbsj">
        <div v-for="item in sbsjList">
          <p>时间：{{item.UFTimeString}}</p>
          <el-button @click="drsbsj(item.FullURL)">导入</el-button>
        </div>
      </div>
    </el-dialog>

    <KEYS v-if="showkeys" :keyval="keyval" @changeval="changeval"></KEYS>
  </div>
</template>

<script>
import pf from "@/publicFn/baseFn.js"
import users from "../../store/moddules/user";
export default {
  name: "corneaMap",
  data() {
    return {
      upimglist: [],
      postData: {
        CL_OP_CTM_ChooseId: "",
        CL_OP_CTM_OD_Cylinder: "",
        CL_OP_CTM_OS_Cylinder: "",
        CL_OP_CTM_OD_Diameter: "",
        CL_OP_CTM_OD_Evalue: "",
        CL_OP_CTM_OD_Extra_Evalue: "",
        CL_OP_CTM_OD_FlatK1: "",
        CL_OP_CTM_OD_FlatK2: "",
        CL_OP_CTM_OD_PupilD: "",
        CL_OP_CTM_OD_SteepK1: "",
        CL_OP_CTM_OD_SteepK2: "",
        CL_OP_CTM_OS_Diameter: "",
        CL_OP_CTM_OS_Evalue: "",
        CL_OP_CTM_OS_Extra_Evalue: "",
        CL_OP_CTM_OS_FlatK1: "",
        CL_OP_CTM_OS_FlatK2: "",
        CL_OP_CTM_OS_PupilD: "",
        CL_OP_CTM_OS_SteepK1: "",
        CL_OP_CTM_OS_SteepK2: "",
        CL_OP_CTM_Remarks: "",
        CL_OP_CTM_isDone: false,
        CL_OP_CTM_OS_S_Evalue: '',
        CL_OP_CTM_OD_S_Evalue: '',
        CL_OP_CTM_OD_S_Extra_Evalue: '',
        CL_OP_CTM_OS_S_Extra_Evalue: '',
        CL_OP_CTM_OD_Cylinde: '',
        CL_OP_CTM_OS_Cylinde: '',
        CL_OP_CTM_OD_Kappa: '',
        CL_OP_CTM_OS_Kappa: '',
        CL_OP_CTM_OD_AnTong: '',
        CL_OP_CTM_OS_AnTong: '',
        CL_OP_CTM_OS_AvgE: '',
        CL_OP_CTM_OD_AvgE: ''
      },
      dialogImageUrl: '',
      disabled: false,
      dialogVisible: false,
      fileLists: [],
      valTypeidx: 1,
      valType: '',
      options: [],
      baiduTo: '',
      oparr: [],
      kfid: '',
      showsbsj: false,
      sbsjList: [],
      showdrsb: false,
      showkeys: false,
      issr: false,
      srval: '',
      keyval: '',
      iszf: 1,

      isdrc: 1,
      contentData: {}
    }
  },
  watch: {
    postData: {
      handler(n, o) {
        this.$store.commit('eyeTest/upCorneaMap', n)
      },
      deep: true
    },
    contentData: {
      handler(n, o) {
        console.log(n)
        if (n) {
          for (let key in n) {
           if (n[key]) this.postData[key]=n[key]
          }
          this.$store.commit('eyeTest/upCorneaMap', this.postData)
        }
      }
    }
  },
  created() {
    document.addEventListener('click', this.getXY, false)
    if (!this.$store.state.users.CsUser.CustomerId) {return false}
    this._api.eyeTest.getCorneaMap()
    .then(res => {
      if (res.GetListResult && res.GetListResult.length >  0) {
        this.postData = this._clJson(res.GetListResult[0].S_OP_Json)
      }
    })

    this._api.publicApi.getImgList('CTM')
        .then(res => {
          let arr = []
          if (res.GetListResult && res.GetListResult.length > 0) {
            for (let i = 0; i < res.GetListResult.length; i++) {
              arr.push({
                name: res.GetListResult[i].UFId,
                url: res.GetListResult[i].FullURL,
              })
            }
            this.fileLists = arr
          }
        })

    this._api.publicApi.getOCRType('角膜地形图')
        .then(res => {
          let arr = []
          this.baiduTo = res.BaiduToken
          if (res.GetListResult && res.GetListResult.length > 0) {
            this.oparr = JSON.parse(JSON.stringify(res.GetListResult))
            for (let i = 0; i < res.GetListResult.length; i++) {
              arr.push({
                value: i + 1,
                label: res.GetListResult[i].BDOCR_TempName
              })
            }
          }
          this.options = arr
        })
  },
  methods: {
    handleRemove(file) {
      this._api.publicApi.deleImg(file.name)
          .then(res => {
            if (res.DeleteByIdReturn) {
              let upwj = this.$refs.uptq.uploadFiles
              for (let i = 0; i < upwj.length; i++) {
                  if (upwj[i]['url'] == file.url) {
                  upwj.splice(i, 1)
                }
              }
            }
          })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      //console.log(file);
    },

    dqwj() {
      if (!this.$store.state.users.employees) {
        return this.$alert('员工未登录请登录！', '提示')
        .then(res => {this.$router.push({path: '/employeesLanding'})})
      }
      if (!this.valTypeidx) {
        return this.$alert('请选择类型！', '提示')
      }

      if (!this.isdrc) return

      this.isdrc = 0

      this.wjsc()

      let str = this.$store.state.physicianVisits.xzConsulting.slice(0,8).toUpperCase()
      let name = this.$store.state.users.CsUser.CustomerName,
          ys = this.$store.state.users.employees.ExpertName,
          temid = this.oparr[this.valTypeidx-1].BDOCR_TempId
      let obj = {
        ChooseNo: str,
        Expert: ys,
        Customer: name,
        CheckType: "角膜地形图",
        TempId: temid,
        BDToken: this.baiduTo,
        PageSite: this.oparr[this.valTypeidx-1].BDOCR_PageSite,
        ImageSite: this.oparr[this.valTypeidx-1].BDOCR_ImageSite,
        "OcrId": this.oparr[this.valTypeidx-1].BDOCR_Id,
        "TempName": this.oparr[this.valTypeidx-1].BDOCR_BrandName,
        "CompanyId": this.$store.state.users.user.Company.CompanyId,
        "ShopId": this.$store.state.bases.shop.ShopId,
        "ChooseId": this.$store.state.physicianVisits.xzConsulting,
        "HealthCheckId":'',
        "CustomerId": this.$store.state.users.CsUser.CustomerId,
        "ExpertId":  this.$store.state.users.employees?this.$store.state.users.employees.ExpertId:"00000000-0000-0000-0000-000000000000"
      }

      window.bound.selReport(JSON.stringify(obj));

      /*this._api.publicApi.OCRLfirst(this.oparr[this.valTypeidx-1].BDOCR_BrandName, this.oparr[this.valTypeidx-1].BDOCR_Id)
          .then(res => {
            // console.log(res)
            if (res.ReturnCode == 5000) {
              this.kfid = res.Result
              window.bound.selReport(JSON.stringify(obj));
            }else {
              this.$alert(res.displaymsg, '提示')
            }
          })*/

    },

    dqsbsj() {
      this.showsbsj = true
      this._api.publicApi.getImgList('ShopExamFileCTM', this.$store.state.bases.shop.ShopId)
          .then(res => {
            // console.log(res)
            if (res.GetListResult && res.GetListResult.length) {
              this.sbsjList = [...res.GetListResult]
              return
            }
            this.sbsjList = []
          })
    },

    drsbsj(url) {
      let log = this.$loading({
        text: '识别中...'
      })
      this.wjsc(log)
      let temid = this.oparr[this.valTypeidx-1].BDOCR_TempId
      let item = {
        "TempId": temid,
        "BDToken": this.baiduTo,
        "FilePath": url
      }
      window.bound.selShopFile(JSON.stringify(item))
    },

    sc(file, f) {
      let _this = this
      let reader = new FileReader(), imgBase64 = '';
      reader.readAsDataURL(file.raw)
      reader.onload = function (e) {
        _this._http.post(pf.getUrl('UploadFile_Upload'), {
          "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
          },
          o: {
            FileBase64: e.currentTarget.result.split(',')[1],
            ExtensionName: ".png",
            UFSaveType: 'OSSPrivate',
            UFReObject: "Choose",
            UFType: 'CTM',
            UFReId: _this.$store.state.physicianVisits.xzConsulting
          }
        })
            .then(res => {
              if (res.Id) {
                file.name = res.Id
              }
            })
      }
    },

    convertBase64UrlToBlob(urlData){
      let arr = urlData.split(','),
          bstr = window.atob(urlData), n = bstr.length, u8arr = new window.Uint8Array(n);
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new window.Blob([u8arr], {type:'png'});
    },

    wjsc(log) {
      let _this = this
      window.getReportResult = function (res) {
        // let upwj = _this.$refs.uptq.uploadFiles
        // let obj = JSON.parse(JSON.stringify(_this.postData))
        _this.isdrc = 1
        if (log) log.close()
        _this.showsbsj = false
        if (res.BaiduResult) {
          let datas = JSON.parse(res.BaiduResult).data
          if (datas.ret) {
            let item = {},obj = JSON.parse(JSON.stringify(_this.postData))
            for (let i = 0; i < datas.ret.length; i++) {
              let str = datas.ret[i].word?datas.ret[i].word.toString():''
              switch (datas.ret[i].word_name) {
                case 'CL_OP_CTM_OD_SteepK1':
                  obj.CL_OP_CTM_OD_SteepK1 = str
                      break
                case 'CL_OP_CTM_OD_SteepK2':
                  obj.CL_OP_CTM_OD_SteepK2 = str
                  break
                case 'CL_OP_CTM_OS_FlatK2':
                  obj.CL_OP_CTM_OS_FlatK2 = str
                  break
                case 'CL_OP_CTM_OS_Extra_E':
                  obj.CL_OP_CTM_OS_Extra_Evalue = str
                  break
                case 'CL_OP_CTM_OD_Extra_E':
                  obj.CL_OP_CTM_OD_Extra_Evalue = str
                  break
                case 'CL_OP_CTM_OD_Evalue':
                  obj.CL_OP_CTM_OD_Evalue = str
                  break
                case 'CL_OP_CTM_OS_SteepK2':
                  obj.CL_OP_CTM_OS_SteepK2 = str
                  break
                case 'CL_OP_CTM_OS_Evalue':
                  obj.CL_OP_CTM_OS_Evalue = str
                  break
                case 'CL_OP_CTM_OS_SteepK1':
                  obj.CL_OP_CTM_OS_SteepK1 = str
                  break
                case 'CL_OP_CTM_OS_Cyl':
                  obj.CL_OP_CTM_OS_Cylinder = str
                  break
                case 'CL_OP_CTM_OD_Cyl':
                  obj.CL_OP_CTM_OD_Cylinder = str
                  break
                case 'CL_OP_CTM_OS_FlatK1':
                  obj.CL_OP_CTM_OS_FlatK1 = str
                  break
                case 'CL_OP_CTM_OD_FlatK2':
                  obj.CL_OP_CTM_OD_FlatK2 = str
                  break
                case 'CL_OP_CTM_OD_FlatK1':
                  obj.CL_OP_CTM_OD_FlatK1 = str
                  break
                case 'CL_OP_CTM_OD_S_Evalu':
                  obj.CL_OP_CTM_OD_S_Evalue = str
                  break
                case 'CL_OP_CTM_OS_S_Evalu':
                  obj.CL_OP_CTM_OS_S_Evalue = str
                  break
                case 'CL_OP_CTM_OD_S_Extra':
                  obj.CL_OP_CTM_OD_S_Extra_Evalue = str
                  break
                case 'CL_OP_CTM_OS_S_Extra':
                  obj.CL_OP_CTM_OS_S_Extra_Evalue = str
                  break
                case 'CL_OP_CTM_OD_Cylinde':
                  obj.CL_OP_CTM_OD_Cylinder = str
                  break
                case 'CL_OP_CTM_OS_Cylinde':
                  obj.CL_OP_CTM_OS_Cylinder = str
                  break
                case 'CL_OP_CTM_OD_PupilD':
                  obj.CL_OP_CTM_OD_PupilD = str
                  break
                case 'CL_OP_CTM_OS_PupilD':
                  obj.CL_OP_CTM_OS_PupilD = str
                  break
                case 'CL_OP_CTM_OS_Diamete':
                  obj.CL_OP_CTM_OS_Diameter = str
                      break
                case 'CL_OP_CTM_OD_Diamete':
                  obj.CL_OP_CTM_OD_Diameter = str
              }
            }
            _this.postData = obj
            // _this._api.publicApi.OCRsecond(_this.kfid, true)
            // .then(res => {
            //   console.log(res)
            // })
          }
        }


        if (res.ReportImages && res.ReportImages.length) {
          for (let i = 0; i < res.ReportImages.length; i++) {
            _this._api.publicApi.upFiles(res.ReportImages[i].ImageBase64, 'CTM')
                .then(res => {
                  _this.upimglist.push(res)
                })
            /*let formData = new window.FormData() ;
            let blobDate = _this.convertBase64UrlToBlob( res.ReportImages[i].ImageBase64 );
            let url = window.URL.createObjectURL(blobDate)
            let stri = pf.randomString(6)+".png"
            formData.append(stri, blobDate); // 文件对象
            _this._http.post(pf.getUrl('UploadFile_Upload'), {
              "token": {
                "TimeSpan": 1566827733,
                "sToken": "768DCAFFF869CD61372790C2738012EC"
              },
              o: {
                FileBase64:  res.ReportImages[i].ImageBase64,
                ExtensionName: "."+formData.get(stri).type,
                UFSaveType: 'OSSPrivate',
                UFReObject: 'Choose',
                UFType: 'CTM',
                UFReId: _this.$store.state.physicianVisits.xzConsulting
              }
            })
                .then(r => {
                  if (r.Id) {
                    upwj.push({
                      name: r.Id,
                      raw: formData.get(stri),
                      status: "ready",
                      percentage: 0,
                      size: formData.get(stri).size,
                      uid: pf.randomString(18),
                      url
                    })
                  }
                })*/
          }
        }
        if (res.ReportPages && res.ReportPages.length) {
          for (let i = 0; i < res.ReportPages.length; i++) {
            _this._api.publicApi.upFiles(res.ReportPages[i].PageBase64, 'CTM')
                .then(res => {
                  _this.upimglist.push(res)
                })
            /*let formData = new window.FormData() ;
            let blobDate = _this.convertBase64UrlToBlob( res.ReportPages[i].PageBase64 );
            let url = window.URL.createObjectURL(blobDate)
            let stri = pf.randomString(6)+".png"
            formData.append(stri, blobDate); // 文件对象
            _this._http.post(pf.getUrl('UploadFile_Upload'), {
              "token": {
                "TimeSpan": 1566827733,
                "sToken": "768DCAFFF869CD61372790C2738012EC"
              },
              o: {
                FileBase64:  res.ReportPages[i].PageBase64,
                ExtensionName: "."+formData.get(stri).type,
                UFSaveType: 'OSSPrivate',
                UFReObject: "choose",
                UFType: 'CTM',
                UFReId: _this.$store.state.physicianVisits.xzConsulting
              }
            })
                .then(r => {
                  if (r.Id) {
                    upwj.push({
                      name: r.Id,
                      raw: formData.get(stri),
                      status: "ready",
                      percentage: 0,
                      size: formData.get(stri).size,
                      uid: pf.randomString(18),
                      url
                    })
                  }
                })*/
          }
        }
      }
    },

    save() {
      let OeyeBiologyTest = document.getElementById('corneaMap'),
          arrcw = OeyeBiologyTest.getElementsByClassName('cw')
      if (arrcw.length > 0) {
        this.$confirm('请复核红色区域数据是否异常！', '提示', {
          confirmButtonText: '继续提交',
          cancelButtonText: '取消',
          type: 'warning'
        })
            .then(() => {
              this._api.eyeTest.saveCorneaMap()
            }).catch(() => {
            });
      }else {
        this._api.eyeTest.saveCorneaMap()
      }
    },

    zh(key, type=0) {
      this.postData[key] = this.postData[key]?this.postData[key].toString():''
    },



    keyd(e) {
      // e.returnValue= false
    },

    srjc(val, min, max) {
      if (val) {
        if (val.indexOf('^') > -1) return false
        if (isNaN(Number(val))) return true
        if (Number(val) > Number(max)) return true
        if (Number(val) < Number(min)) return true
        return false
      }
      return false
    },

    getgb(str, key, iszf) {
      // console.log(e)
      // document.activeElement.blur();
      this.keyval = str
      this.srval = key
      this.issr = true
      this.iszf = iszf
    },

    getXY(e) {
      if (this.issr) {
        this.$store.commit('isShow/upkeyh', e.y)
        this.showkeys = true
        this.issr = false
      }else  {
        this.showkeys = false
        this.srval = ''
      }

    },

    changeval(e) {
      this.keyval = e
      this.$set(this.postData, this.srval, e)
      if (this.postData[this.srval] && !isNaN(Number(this.postData[this.srval])) && this.iszf) {
        if (this.postData[this.srval] && Number(this.postData[this.srval]) > 0 && this.postData[this.srval].indexOf('+') < 0  && this.postData[this.srval].indexOf('-') < 0) {
          this.$set(this.postData, this.srval, '+'+e)
        }
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.getXY)
  },
  components: {
    KEYS: () => import('@/components/keyboard/keyHome'),
    UPfile: () => import('@/components/CommonComponents/upFile'),
    UPFILE: () => import('../../components/sbData')
  }
}
</script>

<style scoped lang="scss">
  .corneaMap {
    width: 90%;
    margin: 0 auto;
  }
  h3{padding-bottom: 0.3rem}
  .xxxx {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-top: 20px;
    li {
      width: 9vw;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    li:nth-child(1) {
      justify-content: start;
      width: 5%;
    }
    .pj {margin: 0.03rem 0.2rem;}
  }
  ::v-deep  .xxxx li input:hover{background: #e8e6e6}
  ::v-deep .xz input {background: #e8e6e6}
  ::v-deep .cw  input{background: #ef4674;}
  .bz {
    text-align: left;
    padding-top: 50px;
    padding-bottom: 30px;
  }
  .bz p {padding-bottom: 10px;font-size: 17px}
  .tp {
    display: flex;
    justify-content: start;
    padding-top: 0.4rem;
  }
  .xzlex {
    width: 200px;
    margin-right: 2vw;
  }
  .cw {background: #ef4674;}
  ::v-deep .cw .el-input__inner{background: #ef4674 !important;}

  .sbsj {
    max-height: 300px;
    overflow-y: auto;
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
</style>
